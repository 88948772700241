
.list-name {
  font-size: 1.5rem;
}
table {
  width: 100%;
}
table th, table td {
  border: 1px #c0c0c0 solid;
}
